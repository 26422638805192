@font-face {
    font-family: "Gibson-Regular";
    src: url("/public/assets/fonts/Gibson-Regular.otf") format("truetype");
}

@font-face {
    font-family: "Gibson-Bold";
    src: url("/public/assets/fonts/Gibson-Bold.otf") format("truetype");
}

html {
    scroll-behavior: smooth
}

body {
    font-family: 'Gibson-Regular' !important;
    font-size: 18px;
    line-height: 24px;
    font-weight: normal;
}

.upper-case-text {
    text-transform: uppercase;
}

h1,
h2,
h3 {
    font-family: "Gibson-Bold";
}

.h1,
h1 {
    font-size: 32px;
    text-align: center;
}

h2 {
    font-size: 22px;
}

h3 {
    font-size: 16px;
}

.f22 {
    font-size: 22px;
}

.f22 button {
    font-size: 22px !important;
    font-weight: 900;
}

.main-nav-center {
    flex: 6;
}

.pagenote {
    background-color: #33333b;
    color: #fff;
    padding: 14px;
    text-align: center;
}

.navbar {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(25, 59, 104, 0.25);
    padding-top: 0;
    padding-bottom: 0;
}

.navbar-brand {
    padding: 0;
    max-height: 76px;
}

.navbar-brand img {
    width: 70px;
    height: 70px;
}

.navbar-light .navbar-nav .nav-link {
    color: #18407a !important;
    text-transform: uppercase;
    font-style: normal;
    font-size: 18px;
    line-height: 50px;
    text-decoration: none;
    border: 0;
    padding-left: 15px;
    padding-right: 15px;
}

.nav-link {
    padding: 0.5rem 1rem 0.5rem !important;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    background: #fff;
    border: 0;
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
    color: #0b0c10;
    border-bottom: none;
}

.nav-link-custom .nav-item-custom {
    color: #0e6eb4;
}



ul.dropdown-menu {
    padding: 0;
    box-shadow: 0px 4px 20px rgba(25, 59, 104, 0.25);
}

.navbar-nav .dropdown-menu {
    border: 2px solid #18407a;
    border-radius: 0;
}

.navbar-divide-bot {
    border-bottom: 2px solid #18407a;
}

.navbar-divide-top {
    border-top: 2px solid #18407a;
    margin-top: 2px
}

.navbar-nav .dropdown-menu .dropdown-item {
    padding: 0;
}

/* 
.navbar-nav .dropdown-menu .dropdown-item a,
.navbar-nav .dropdown-menu button.btn-profile{
    color: #18407a;
    text-decoration: none;
    margin: 0;
    padding: 4px 15px 4px 0;
    display: block;
    width: 100%;
} */

.dropdown-item.active,
.dropdown-item:active {
    background-color: #fff;
    color: #0b0c10;
}

.dropdown-toggle::after {
    display: none;
}

.user-menu-icon {
    border: 0;
    background: none;
}

.userprofile-icon {
    width: 40px;
    vertical-align: text-bottom;
}

.dd-item-icon {
    width: 32px;
    padding-right: 6px;
}

.user-menu ul.dropdown-menu {
    padding-bottom: 15px;
}

.user-menu .dropdown-item a,
.user-menu .dropdown-item button {
    text-decoration: none;
    display: block;
    text-align: left;
    color: #18407a;
    line-height: 34px;
    padding-left: 0;
    width: 100%;
}

.user-menu .dropdown-menu button.btn-profile {
    padding: 0;
    outline: none;
    border: none;
}

.carousel-control-prev,
.carousel-control-next,
.carousel-indicators {
    display: none;
}

.asterisk:after {
    content: "*";
    width: 5px;
    height: 5px;
    color: red;
    font-weight: bold;
}

.visible-hidden {
    opacity: 0;
    display: none;
}

.v-align-baseline {
    align-items: baseline;
}

.form-group {
    display: block;
    margin-bottom: 30px;
}

.col-form-label {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.form-group .form-control {
    background: #FFFFFF;
    color: #000;
    border: none;
    border-bottom: 3px solid #000;
    height: 36px;
    border-radius: 0px;
    font-size: 18px;
    padding: 5px 15px 5px 0;
    outline: 0;
}

.form-control:focus {
    border-color: #000;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}


.form-group .form-control::placeholder {
    color: #fff;
    font-size: 18px;
    font-weight: 300 !important;
}

.form-group .show-placegholder::placeholder {
    color: #bebebe !important;
    font-weight: 300 !important;
}

.agreewith {
    margin-top: 0.5rem;
    margin-left: 10px;
}

.main-heading {
    margin: 20px 0px 50px 0px !important;
    /* font-weight: 700; */
}

.sub-heading {
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #4b4b4b;
    padding-bottom: 50px;
}

.sub-heading a {
    text-decoration: underline;
}

.skiptocontent {
    position: absolute;
    opacity: 0;
    left: 50%;
    top: 0;
    transition: -webkit-transform .166s;
    transition: transform .166s;
    transition: transform .166s, -webkit-transform .166s;
    -webkit-transform: translate(-50%, -110%);
    -ms-transform: translate(-50%, -110%);
    transform: translate(-50%, -110%);
}


.btn-primary {
    margin-top: 20px;
    background: #18407a;
    color: #fff;
    text-transform: uppercase;
    /* font-weight: 600; */
    font-size: 18px;
    line-height: 28px;
    border: none;
    min-width: 160px;
    letter-spacing: 1px;
    padding: 8px 40px 8px;
    text-decoration: none;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled):active {
    background: #0e6eb4;
}

.control-disabled:disabled {
    color: #666 !important;
}


.btn-link {
    color: #18407a;
    text-decoration: underline;
    font-size: 18px;
}

a {
    color: #18407a;
    text-decoration: underline;
}

a:hover,
a:focus {
    color: #0e6eb4;
    text-decoration: none;
}

.footer-link li:focus-within {
    background: none;
}

.btn-light {
    color: #18407a;
    font-size: 18px;
    line-height: 28px;
    text-transform: uppercase;
    transition: ease 0.3s;
}

.btn-light:hover,
.btn-light:focus {
    background-color: #18407a;
    color: #fff;
}

.btn-bold {
    font-size: 32px;
    font-family: "Gibson-Bold";
    color: #18407a;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 2px;
    transition: ease 0.3s;
}

.btn-bold:hover,
.btn-bold:focus {
    color: #0e6eb4;
}

.card {
    border: 1px solid #D9D9D9;
    border-radius: 30px;
    padding: 20px;
}

.faq-page .card {
    padding: 0;
    margin: 1px 0;
    border: none;
    border-radius: 0;
    background: #18407a;
    color: #fff;
}

.faq-page .card-header {
    padding: 5px 15px
}

.faq-page .nav-link {
    padding: 20px 0 !important;
}

.faq-line-icon {
    color: #fff;
    list-style: 32px;
    font-size: 22px;
    font-weight: bold;
}

.faq-page .card-body {
    padding: 2rem 1.25rem;
}

.same-height {
    display: flex;
}


/* reward page */
.rewards-page .reward-title {
    text-transform: uppercase;
    font-size: 18px;
    margin-top: 5px;
    font-weight: bold;
    color: #000;
}

.cursor-pointer {
    cursor: pointer;
}

.rewards-page-modal .form-group .form-control {
    border-bottom: none;
    padding-left: 15px;
    height: 46px;
}

.card-cta .card {
    padding: 0;
}

.card-cta .card:hover {
    background-color: #c0daec;
    border-color: #738eb6;
    cursor: pointer !important;
}

.card-cta img {
    border-radius: 20px;
    margin: 0 0 1rem 0;
}

.card-p {
    padding: 10px 20px;
}

.btn-position-bottom {}



/* footer */
footer {
    background-color: #4b4b4b;
    margin-top: 30px;
    color: #fff;
}

.footer {
    padding-top: 32px;
    padding-bottom: 32px;
}

footer a {
    color: #fff;
    transition: 0.5s;
    text-decoration: none;
}

footer a:hover,
footer a:focus,
.footer-link li:focus-within {
    color: #fff;
    text-decoration: underline;
}

.footer-logo_wrap {
    justify-content: center;
    display: flex;
}

.footer-logo_wrap img {
    width: 100px;
    height: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

/* .social-links{justify-content: left !important;}
.footer .footer-link{justify-content: left !important;} */
.footer .footer-link ul {
    padding-left: 0;
    text-align: center;
}

.footer .footer-link ul li {
    padding: 0 6px;
    display: inline-block;
    line-height: 32px;
    float: inherit;
}

.footer .footer-link ul li a[type=button] {
    color: #fff;
}

.footer .footer-link ul li a[type=button]:hover,
.footer .footer-link ul li a[type=button]:focus {
    color: #fff;
    text-decoration: none;
}


.footer-social li {
    padding: 0;
}

.footer-social li a {
    padding: 5px 15px;
    font-size: 22px;
    color: #fff;
}

.footer-social li a:hover,
.footer-social li a:focus {
    background: none;
}

.adchoices {
    height: 18px;
    margin-left: 4px;
    margin-top: -3px;
}

.footer-bot {
    box-shadow: 0px 4px 20px rgba(25, 59, 104, 0.15);
    padding-top: 12px;
}

button.poplink {
    color: #fff;
    font-size: 18px;
    padding: 0px 0 3px;
}

button.poplink:hover {
    color: #fff;
    text-decoration: underline;
}

.footer-bot .footer-link {
    justify-content: right !important;
    color: #fff;
}

.copy-text {
    margin: 0 0 10px !important;
}

.tierlogic {
    margin-top: 15px;
    padding-top: 15px;
}

.tierlogic a {
    line-height: 50px;
}

.tierlogic img {
    max-height: 30px;
}

/* end footer */


.input-error {
    font-size: 18px;
    margin-top: 4px;
}

.portal-point-list-container {
    padding: 40px 0px 40px;
}

.portal-point-list-container .label,
.header-parent-label {
    color: #33333b;
    border-bottom: none;
    text-align: center;
    font-size: 35px;
}

.section-content {
    margin: 40px auto;
}

.custom-tooltip-wrap .btn {
    min-width: 26px;
    padding: 0;
    height: 26px;
    border-radius: 80px;
    position: relative;
    left: 6px;
    font-size: 12px;
    margin: 0;
    top: -3px;
}

.inline-checkboxes {
    display: flex;
    align-items: flex-start;
}

.inline-checkboxes label {
    margin-top: -5px;
    margin-left: 10px;
}

.form-check-input {
    margin-top: 0.15rem !important;
    margin-left: -0.9rem;
    height: 16px;
    width: 16px;
}

.form-check-label {
    margin-left: 14px;
}

ul.listing-points {
    margin: 20px 0;
    padding: 0;
}

ul.listing-points li {
    margin: 10px 0;
    padding: 5px 0px;
    text-align: justify;
}

.modal-body ul.listing-points li a,
.modal-content a {
    color: #fff !important;
}

.modal-body ul.listing-points li a:hover,
.modal-body ul.listing-points li a:focus {
    color: #fff;
}


/* after login */
.table-bordered td {
    border: none;
}

.table-bordered tbody tr:first-child td {
    padding-top: 30px;
}

.table-bordered tbody tr:last-child td {
    padding-bottom: 30px;
}

.mya_table table thead {
    background-color: #18407a;
    color: #FFFFFF;
    font-weight: normal !important;
}

.pagination-block {
    display: flex;
    align-items: center;
    padding-top: 15px;
}

.pagination-block .pagination.react-bootstrap-table-page-btns-ul {
    float: none;
}

.page-item.disabled .page-link {
    text-decoration: none;
}

.page-item.active .page-link {
    background-color: #18407a;
    border-color: #18407a;
    text-decoration: none;
}

.pagination>.active>a:hover {
    background-color: #0e6eb4;
    border-color: #0e6eb4;
}

.pagination .disabled a {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}

.pagination>li>a {
    border: 1px solid #18407a;
}

.pagination .previous a {
    border-radius: 4px 0 0 4px !important;
}

.pagination .next a {
    border-radius: 0 4px 4px 0 !important;
}

.page-tab_nav a {
    color: #18407a;
    font-size: 18px;
    line-height: 28px;
    text-decoration: none;
    font-weight: bold;
    letter-spacing: 1px;
    border: none;
    min-width: 160px;
    padding: 10px 40px 10px;
}

.active-page {
    background: #18407a !important;
    color: #fff !important;
    border-radius: 4px;
}

.dropzone-div {
    background: #efeeee;
    padding-top: 100px;
    padding-bottom: 100px;
}

.modal-content {
    border-radius: 3rem;
    background-color: #18407a;
    color: #fff;
    padding: 1.5rem .8rem;
    font-weight: normal;
}

.modal-heading {
    font-size: 22px;
}

.modal-content .btn-modal {
    background-color: #fff;
    color: #18407a;
}

.modal-content a {
    color: #1ea8e0;
}

.modal-content .section-content {
    margin: 0 auto;
}

.close {
    color: #fff;
    opacity: .7;
    text-shadow: 0 1px 0 #000;
}

.close:hover {
    color: #D9D9D9;
}

ul.checkmark {
    margin: 0;
    padding: 0;
}

ul.checkmark li {
    font-size: 16px;
    margin-bottom: 1em;
    list-style-type: none;
    padding: .25em 0 0 2.5em;
    position: relative;
}

ul.checkmark li:before {
    content: " ";
    display: block;
    border: solid .8em #1ea8e0;
    border-radius: .8em;
    height: 0;
    width: 0;
    position: absolute;
    left: .5em;
    top: 35%;
    margin-top: -.5em;
}

ul.checkmark li:after {
    content: " ";
    display: block;
    width: .3em;
    height: .6em;
    border: solid #fff;
    border-width: 0 .2em .2em 0;
    position: absolute;
    left: 1.2em;
    top: 38%;
    margin-top: -.2em;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-collapse {
        margin-left: 50px;
    }
}



@media (min-width: 576px) {
    .mobile-hide {
        display: none !important;
    }
}

@media (max-width: 768px) {
    .user-pos {
        position: absolute;
        right: 100px;
        top: 10px;
    }
}

.mob-menu-pos {
    float: right !important
}

@media (max-width: 575.98px) {
    .desktop-hide {
        display: none !important;
    }

    .page-tab_nav .col {
        padding-left: 5px;
        padding-right: 5px;
    }

    .page-tab_nav a {
        min-width: auto;
        padding: 10px;
        display: inline-block;
    }

    .btn-primary {
        min-width: 180px;
    }

    .btn-secondary {
        max-width: 100px !important;
        min-width: 100px;
        padding-left: 10px;
        padding-right: 10px;
        margin-left: 35px;
    }

    .navbar-light .navbar-nav .nav-link {
        line-height: 36px !important;
    }

    .user-menu {
        margin-left: 16px;
        width: 100%;
    }

    .user-menu .dropdown-menu {
        min-width: 260px;
    }

    .user-pos {
        position: absolute;
        right: 80px;
        top: 10px;
    }

    .mob-menu-pos {
        float: right !important
    }
}

.react-bootstrap-table table {
    table-layout: auto;
}

.banner-notice {
    color: white;
    background-color: black;
    padding: 10px;
}